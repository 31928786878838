@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;600;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,900&family=Nunito:wght@200;300;400;500;600;700;800;900;1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Ysabeau+SC:wght@1;100;200;300;400;500;600;700;800;900;1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");
@font-face {
  font-family: "Alta";
  src: url("/src/assets/Alta_light.otf") format("openType");
  font-weight: 100;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
  color: rgb(34, 34, 34);
  overflow-x: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.link-styles {
  text-decoration: none;
  color: black;
}

:root {
  --primaryColor: #baad81;
  --primaryColorDark: #2e68e6;
  --primaryColorLight: #73bbc9b6;
  --primaryBackgroundColor: #ffffff;
  --secondaryBackgroundColor: #e6e6e6;
  --trBackgroundColor: #ffffff63;
  --fontPrimaryColor: #383838;
  --fontPrimaryColorOpp: #e7e7e7;
  --fontSecondaryColor: #7a7a7a;
  --scrollbarColor: #cacaca;
  --bgColor2: #d6d5d5;
  --shadowColor: #979797;
  --shadowColor1: #d8d8d8;
  --shadowColor2: #ececec;
}

::-webkit-scrollbar {
  width: 0.7em;
}
body::-webkit-scrollbar-track {
  background: #ffffff;
}
body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #dcd7d2;
}
body::-webkit-scrollbar-thumb:hover {
  background: #dcd7d2;
}
